<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="名称" prop="card_name" :rules="rules.required">
					<el-input v-model="form.card_name"></el-input>
				</el-form-item>
				<el-form-item label="充值金额" prop="recharge_money" :rules="[rules.required,rules.notZero]">
					<el-input-number v-model="form.recharge_money" :precision="2" :step="0.1" :min="0" :max="99999"></el-input-number>
					<span class="margin-left-sm text-grey">元</span>
				</el-form-item>
				<el-form-item label="赠送金额" prop="give" :rules="rules.required">
					<el-input-number v-model="form.give" :precision="2" :step="0.1" :min="0" :max="99999"></el-input-number>
					<span class="margin-left-sm text-grey">元</span>
				</el-form-item>
				<el-form-item label="销售提成" prop="give" :rules="rules.required">
					<el-input-number v-model="form.sales_commission" :precision="2" :step="0.1" :min="0" :max="99999"></el-input-number>
					<span class="margin-left-sm text-grey">元</span>
				</el-form-item>
				<el-form-item label="上架" prop="status">
					<el-switch v-model="form.status" :active-value="1" :inactive-value="0">
					</el-switch>
				</el-form-item>
				<el-form-item label="说明" prop="explain">
					<el-input type="textarea" v-model="form.explain"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">保存</el-button>
					<el-button @click="$router.back()">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	export default {
		data() {
			return {
				rules,
				title: this.$route.params.id ? "编辑服务卡" : "新增服务卡",
				form: {
					id: this.$route.params.id,
					card_name: '', // 	是	string	充值卡名称
					recharge_money: 0, //	是	int	充值金额
					give: 0, //	是	int	赠送金额
					sales_commission: 0, // 销售提成
					status: 1, //	是	int	状态（0：下架 1：上架）
					explain: '',// 说明
				}
			};
		},
		computed: {
			isEdit() {
				return Boolean(this.form.id)
			}
		},
		mounted() {
			// 查询
			if (this.isEdit) {
				this.$api.card.getRechargeById({
					id: this.form.id
				}).then(res => {
					this.form = res.data
				})
			}
		},
		methods: {
			onSubmit() {
				this.$refs.form.validate(valid => {
					// 表单校验
					if (valid) {
						const loading = this.$loading();
						this.$api.card.saveRecharge(this.form).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}
</style>
